@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
#root,
body {
  height: 100%;
  margin: 0;
}
@media screen and (min-width: 600px) {
  body {
    font-size: 18px;
  }
  section {
    position: relative;
    padding: 6rem;
    padding-bottom: 0;
  }
}
@media screen and (max-width: 599px) {
  body {
    font-size: 16px;
  }
  section {
    position: relative;
    padding: 1rem;
    padding-bottom: 0;
  }
}
body {
  margin: 0;
  padding: 0;
  background: #272b33;
  color: white;
  font-family: 'Montserrat', sans-serif;
  min-width: 320px;
  overflow-x: hidden;
}
button > span {
  font-family: 'Montserrat', sans-serif;
}
.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}
section h1 {
  text-align: center;
}

@media screen and (min-width: 599px) {
  .profile {
    width: 120px;
    height: 120px;
  }
}
@media screen and (max-width: 599px) {
  .profile {
    width: 150px;
    height: 150px;
  }
}
@media screen and (min-height: 599px) {
  .scroll-down {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 170px;
  }
}
@media screen and (max-height: 599px) and (min-height: 410px) {
  .scroll-down {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 100px;
  }
}
@media screen and (max-height: 409px) {
  .scroll-down {
    display: none;
  }
}
.home {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: calc(100vh + 63px);
  z-index: 1000;
}
.greeting {
  margin: -63px 20px 0 20px;
}
.hide {
  display: none;
}
.profile {
  border-radius:200%;
  border: 0.2rem solid #00407a;
}
.greeting-text {
  margin: 20px;
}
.name {
  color: #00A3E1;
}
.links {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px;
  max-width: 60px;
  margin: 10px auto 0 auto;
}
.links a,
.links img {
  width: 50px;
  height: 50px;
}
.scroll-down-element {
  display: inline-block;
}
.wave-emoji {
  -webkit-animation-duration: 1.8s;
          animation-duration: 1.8s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: wave;
          animation-name: wave;
  display: inline-block;
  -webkit-transform-origin: 70% 70%;
          transform-origin: 70% 70%;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.particles {
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100vh);
  width: 100%;
}
@-webkit-keyframes wave {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  10% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  20% {
    -webkit-transform: rotate(12deg);
            transform: rotate(12deg);
  }
  30% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  40% {
    -webkit-transform: rotate(9deg);
            transform: rotate(9deg);
  }
  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@keyframes wave {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  10% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  20% {
    -webkit-transform: rotate(12deg);
            transform: rotate(12deg);
  }
  30% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  40% {
    -webkit-transform: rotate(9deg);
            transform: rotate(9deg);
  }
  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.navbar {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  background-color: #23252a;
  border-bottom: 3px solid #00A3E1;
  pointer-events: fill;
}
.navbar a {
  cursor: pointer;
  font-weight: 600;
}
.navlink-wrapper {
  width: 50%;
}
.navlinks {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}
#hamburger-icon {
  width: 35px;
  height: 21px;
  position: relative;
  margin: 0px auto;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
#hamburger-icon span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: white;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}
#hamburger-icon span:nth-child(1) {
  top: 0px;
}
#hamburger-icon span:nth-child(2),
#hamburger-icon span:nth-child(3) {
  top: 9px;
}
#hamburger-icon span:nth-child(4) {
  top: 18px;
}
#hamburger-icon.open span:nth-child(1) {
  top: 9px;
  width: 0%;
  left: 50%;
}
#hamburger-icon.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
#hamburger-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
#hamburger-icon.open span:nth-child(4) {
  top: 9px;
  width: 0%;
  left: 50%;
}
@media screen and (min-width: 600px) {
  .profile {
    width: 120px;
  }
  .hamburger {
    display: none;
  }
  .mobilenav {
    display: none;
  }
}
@media screen and (max-width: 599px) {
  .navlinks {
    display: none;
  }
  .hamburger {
    position: absolute;
    right: 30px;
    cursor: pointer;
  }
}

.mobilenav-wrapper {
  position: absolute;
  top: 63px;
  width: 100%;
  clip: rect(0, 9999px, 9999px, -9999px);
}
.mobilenav {
  background-color: #1a212e;
}
.mobilenav.on {
  transition: opacity ease-out 0.3s, -webkit-transform ease-in-out 0.4s;
  transition: transform ease-in-out 0.4s, opacity ease-out 0.3s;
  transition: transform ease-in-out 0.4s, opacity ease-out 0.3s, -webkit-transform ease-in-out 0.4s;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.mobilenav.off {
  transition: opacity ease-out 0.3s, -webkit-transform ease-in-out 0.4s;
  transition: transform ease-in-out 0.4s, opacity ease-out 0.3s;
  transition: transform ease-in-out 0.4s, opacity ease-out 0.3s, -webkit-transform ease-in-out 0.4s;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}
.mobilenavlinks {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.mobilenavlink {
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}
.mobilenavlinks a {
  text-align: left;
  font-size: larger;
}

.about {
  background-color: #2c323f;
}
.about-text {
  width: 100%;
  max-width: 600px;
}
.about-text > ul {
  list-style-type: none;
}
.about-text > ul > li {
  margin-bottom: 10px;
}
.typewriter {
  display: flex;
  align-items: center;
}
.typewriter-start {
  padding-right: 5px;
}
.typewriter > p {
  margin: 0;
}
.textLink {
  color: #00407a;
  cursor: pointer;
}
.location-wrapper {
  display: flex;
  align-items: center;
  border-top: 1px solid white;
  width: 70%;
}
.skills-wrapper {
  position: relative;
  width: 100%;
  max-width: 600px;
  text-align: center;
}
.skills {
  position: relative;
  padding-left: 0;
  list-style: none;
  font-size: 3rem;
  margin: 0 auto;
  width: 90%;
}
.skill-bar-wrapper {
  overflow: hidden;
  position: relative;
  display: flex;
  background-color: transparent;
  border-radius: 2px;
  width: 600px;
  height: 100px;
}
.skill-bar {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  flex-direction: column;
  font-size: 17px;
  height: 90px;
  background-color: #7a9cbc;
  border-radius: 2px 0px 0px 2px;
}
@media screen and (min-width: 960px) {
  .about-content {
    display: flex;
    justify-content: space-evenly;
  }
  .skill-name {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px 0px 0px 2px;
    left: 0;
    width: 600px;
    height: 90px;
    font-size: 20px;
    background-color: #00407a;
    font-weight: 600;
    color: white;
  }

  .skill-details{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px 0px 0px 2px;
    left: 0;
    width: 600px;
    height: 90px;
    font-size: 12px;
    background-color: #00407a;
    font-weight: 600;
    color: white;

  }
}
@media screen and (max-width: 959px) and (min-width: 600px) {
  .about-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .skill-name {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px 0px 0px 2px;
    left: 0;
    width: 600px;
    height: 90px;
    font-size: 20px;
    background-color: #00407a;
    font-weight: 600;
    color: white;
  }

  .skill-details{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px 0px 0px 2px;
    left: 0;
    width: 600px;
    height: 90px;
    font-size: 12px;
    background-color: #00407a;
    font-weight: 600;
    color: white;

  }
}
@media screen and (max-width: 599px) {
  .about-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .skills {
    width: 100%;
  }
  .skill-name {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px 0px 0px 2px;
    left: 0;
    width: 340px;
    height: 90px;
    font-size: 20px;
    background-color: #00407a;
    font-weight: 600;
    color: white;
  }

  .skill-details{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px 0px 0px 2px;
    left: 0;
    width: 340px;
    height: 90px;
    font-size: 8px;
    background-color: #00407a;
    font-weight: 600;
    color: white;

  }
}

.section-title {
  font-size: 25pt;
  margin-bottom: 10px;
}
.underline {
  border-top: 4px solid #00A3E1;
  margin: 0 auto;
  margin-bottom: 15px;
  width: 70px;
}

.certifications {
  position: relative;
  background-color: #2c323f;
}
.certification-content {
  margin: 0 auto;
  max-width: 1400px;
}

.cert-text{
  text-align: center;
}

.certification-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  padding-left: 0;
  list-style: none;
  margin: 0 auto;
}
.certification-list > li {
  border-radius: 5px;
  margin: 20px 10px;
  width: 390px;
  min-width: 290px;
}
.certification-list > li > div {
  height: 100%;
}

.textLink {
  color: #0be779;
  cursor: pointer;
}

a:link { text-decoration: none; }


a:visited { text-decoration: none; }


a:hover { text-decoration: none; }


a:active { text-decoration: none; }
.cv {
  background-color: #2c323f;
}
.cv-content {
  margin: 0 auto;
  max-width: 1400px;
}

.cv-text{
  text-align: center;
}

.cv-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  padding-left: 0;
  list-style: none;
  margin: 0 auto;
}
.cv-list > li {
  border-radius: 5px;
  margin: 20px 10px;
  width: 390px;
  min-width: 290px;
}
.cv-list > li > div {
  height: 100%;
}

.textLink {
  color: #0be779;
  cursor: pointer;
}

a:link { text-decoration: none; }


a:visited { text-decoration: none; }


a:hover { text-decoration: none; }


a:active { text-decoration: none; }
.experience {
  position: relative;
  background-color: #2c323f;
}
.experience-content {
  margin: 0 auto;
  max-width: 1400px;
}
.experience-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  padding-left: 0;
  list-style: none;
  margin: 0 auto;
}
.experience-list > li {
  border-radius: 5px;
  margin: 20px 10px;
  width: 390px;
  min-width: 290px;
}
.experience-list > li > div {
  height: 100%;
}

.textLink {
  color: #0be779;
  cursor: pointer;
}

.experience-link {
  text-decoration: none;
  height: 100%;
  cursor: default;
}
.experience-card-wrapper {
  height: 100%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  transition: 0.2s;
}
.experience-card {
  position: relative;
  background-color: #3b4353;
  border-radius: 5px;
  align-items: center;
  min-height: 158px;
  transition: 0.2s;
  height: 100%;
  cursor: pointer;
}
.experience-card-wrapper:hover .experience-card {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  background-color: #474f63;
}
.experience-card-top {
  position: relative;
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.experience-card-top > h2 {
  position: absolute;
  top: 20px;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  color: white;
  text-decoration: none;
  z-index: 1;
}

.image-wrapper {
  position: absolute;
  bottom: -50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.25);
}
.experience-card-bottom {
  position: relative;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  height: calc(100% - 180px);
  color: white;
  padding: 65px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.experience-card-bottom h2 {
  margin: 0;
}
.experience-card-bottom h3 {
  margin: 20px 0;
  font-size: 18px;
}
.experience-card-bottom ul {
  list-style-type: disc;
  text-align: left;
}
.experience-card-bottom li {
  font-size: 16px;
}
.experience-card-tech {
  margin-top: 20px;
}
.experience-card-tech > ul {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-top: -7px;
  margin-left: -7px;
}
.experience-card-tech > ul > li {
  font-size: 12px;
  font-weight: 600;
  margin-top: 7px;
  margin-left: 7px;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: rgb(94, 106, 128);
}
.experience-bg {
  position: absolute;
  border-radius: 5px 5px 0 0;
  width: 100%;
  height: 100%;
}
.logo-bg {
  border-radius: 50%;
}
.company-logo {
  max-width: 200px;
  z-index: 1;
  pointer-events: none;
}

@media screen and (max-width: 599px) {
  .experience-card-wrapper:hover .experience-card {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    background-color: #3b4353;
  }
  .experience-card-bottom {
    padding: 65px 15px 20px 15px;
  }

}

.projects {
  position: relative;
  background-color: #2c323f;
}
.projects-content {
  margin: 0 auto;
}

.project-card{
  text-align: center;
  /* height: 400px;
  width: 930px; */
}

.projects-list {
  position: relative;
  padding-left: 0;
  list-style: none;
  margin: 0 auto;
  width: 90%;
}
.projects-content {
  max-width: 1000px;
  max-height: 1000px;
}
.tagslist {
  display: flex;
  width: 90%;
  margin: 30px auto 0 auto;
}
.project-tags {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
}
.more-projects-wrapper {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 599px) {
  .projects-content {
    width: 100%;
  }
  
} 
a:link { text-decoration: none; }


a:visited { text-decoration: none; }


a:hover { text-decoration: none; }


a:active { text-decoration: none; }
.contact {
  background-color: #2c323f;
}
.links {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px;
  max-width: 600px;
  margin: 50px auto 0 auto;
}
.links a, .links img {
  width: 40px;
  height: 40px;
}

.contact-form {
  margin: 40px auto 0 auto;
  width: 100%;
  max-width: 500px;
}
.contact-form {
  display: flex;
  flex-direction: column;
}
.email-success {
  text-align: center;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: white !important;
  -webkit-box-shadow: 0 0 0 30px #3b4353 inset !important;
  caret-color: white;
}

footer {
  background-color: #2c323f;
  padding-bottom: 10px;
}
.copyright {
  text-align: center;
}

.topButton {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: #00407a;
  color: white;
  cursor: pointer;
  padding: 10px;
  border-radius: 15px;
  font-size: 25px;
}
.topButton i {
  display: flex;
}
.topButton.on {
  opacity: 1;
  transition: opacity 0.1s linear;
}
.topButton.off {
  opacity: 0;
  transition: opacity 0.1s linear;
}

