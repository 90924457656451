.about {
  background-color: #2c323f;
}
.about-text {
  width: 100%;
  max-width: 600px;
}
.about-text > ul {
  list-style-type: none;
}
.about-text > ul > li {
  margin-bottom: 10px;
}
.typewriter {
  display: flex;
  align-items: center;
}
.typewriter-start {
  padding-right: 5px;
}
.typewriter > p {
  margin: 0;
}
.textLink {
  color: #00407a;
  cursor: pointer;
}
.location-wrapper {
  display: flex;
  align-items: center;
  border-top: 1px solid white;
  width: 70%;
}
.skills-wrapper {
  position: relative;
  width: 100%;
  max-width: 600px;
  text-align: center;
}
.skills {
  position: relative;
  padding-left: 0;
  list-style: none;
  font-size: 3rem;
  margin: 0 auto;
  width: 90%;
}
.skill-bar-wrapper {
  overflow: hidden;
  position: relative;
  display: flex;
  background-color: transparent;
  border-radius: 2px;
  width: 600px;
  height: 100px;
}
.skill-bar {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  flex-direction: column;
  font-size: 17px;
  height: 90px;
  background-color: #7a9cbc;
  border-radius: 2px 0px 0px 2px;
}
@media screen and (min-width: 960px) {
  .about-content {
    display: flex;
    justify-content: space-evenly;
  }
  .skill-name {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px 0px 0px 2px;
    left: 0;
    width: 600px;
    height: 90px;
    font-size: 20px;
    background-color: #00407a;
    font-weight: 600;
    color: white;
  }

  .skill-details{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px 0px 0px 2px;
    left: 0;
    width: 600px;
    height: 90px;
    font-size: 12px;
    background-color: #00407a;
    font-weight: 600;
    color: white;

  }
}
@media screen and (max-width: 959px) and (min-width: 600px) {
  .about-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .skill-name {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px 0px 0px 2px;
    left: 0;
    width: 600px;
    height: 90px;
    font-size: 20px;
    background-color: #00407a;
    font-weight: 600;
    color: white;
  }

  .skill-details{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px 0px 0px 2px;
    left: 0;
    width: 600px;
    height: 90px;
    font-size: 12px;
    background-color: #00407a;
    font-weight: 600;
    color: white;

  }
}
@media screen and (max-width: 599px) {
  .about-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .skills {
    width: 100%;
  }
  .skill-name {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px 0px 0px 2px;
    left: 0;
    width: 340px;
    height: 90px;
    font-size: 20px;
    background-color: #00407a;
    font-weight: 600;
    color: white;
  }

  .skill-details{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px 0px 0px 2px;
    left: 0;
    width: 340px;
    height: 90px;
    font-size: 8px;
    background-color: #00407a;
    font-weight: 600;
    color: white;

  }
}
