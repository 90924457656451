.projects {
  position: relative;
  background-color: #2c323f;
}
.projects-content {
  margin: 0 auto;
}

.project-card{
  text-align: center;
  /* height: 400px;
  width: 930px; */
}

.projects-list {
  position: relative;
  padding-left: 0;
  list-style: none;
  margin: 0 auto;
  width: 90%;
}
.projects-content {
  max-width: 1000px;
  max-height: 1000px;
}
.tagslist {
  display: flex;
  width: 90%;
  margin: 30px auto 0 auto;
}
.project-tags {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
}
.more-projects-wrapper {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 599px) {
  .projects-content {
    width: 100%;
  }
  
} 
a:link { text-decoration: none; }


a:visited { text-decoration: none; }


a:hover { text-decoration: none; }


a:active { text-decoration: none; }