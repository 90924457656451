.cv {
  background-color: #2c323f;
}
.cv-content {
  margin: 0 auto;
  max-width: 1400px;
}

.cv-text{
  text-align: center;
}

.cv-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  padding-left: 0;
  list-style: none;
  margin: 0 auto;
}
.cv-list > li {
  border-radius: 5px;
  margin: 20px 10px;
  width: 390px;
  min-width: 290px;
}
.cv-list > li > div {
  height: 100%;
}

.textLink {
  color: #0be779;
  cursor: pointer;
}

a:link { text-decoration: none; }


a:visited { text-decoration: none; }


a:hover { text-decoration: none; }


a:active { text-decoration: none; }